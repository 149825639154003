
export default [
  {
    // 未匹配到页面
    path: '*',
    redirect: '/',
  },
  {
    path: "/",
    name: "Layout",
    component: () => import("@/layout/index"),
    children:[
      // 首页
      {
        path: '/', name: 'Index', component:() => import('@/pages/index'),
        meta: { title: '首页', isMenu: true }
      },
      // 产品服务
      {
        path: '/product', name: 'Product', component:() => import('@/pages/product'),
        meta: { title: '产品服务', isMenu: true }
      },
      // 新闻资讯
      {
        path: '/news', name: 'News', component:() => import('@/pages/news'),
        meta: { title: '新闻资讯', isMenu: true }
      },
      // 免费试用
      {
        path: '/free', name: 'Free', component:() => import('@/pages/free'),
        meta: { title: '免费试用', isMenu: true }
      },
      // 付费会员
      {
        path: '/member', name: 'Member', component:() => import('@/pages/member'),
        meta: { title: '付费会员', isMenu: true }
      },
    ],
  },
  
]
