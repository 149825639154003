import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { loadInterceptors } from '@/services/request'
import interceptors from '@/services/axios-interceptors'
import { Button,Message,FormModel, Input,Icon, Pagination } from 'ant-design-vue';
import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style/common.less'

Vue.use(Button)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(BootstrapVue)
Vue.prototype.$message = Message;
loadInterceptors(interceptors, {Message})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
